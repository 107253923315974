@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-page {
  $self: &;
  padding-top: 80.5px;
  #{$self}__first-block {
    margin-top: 56px;
  }
  #{$self}__last-block {
    margin-bottom: 48px;
  }
  #{$self}__header-image-block {
    margin-top: 32px;
  }
  #{$self}__block {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  #{$self}__block-alt {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  #{$self}__block-separator {
    height: 1px;
  }
  #{$self}__card-block {
    padding: 24px;
  }
  #{$self}__section {
    margin-top: 36px;
  }
}

@include bd-md-and-up() {
  .bd-page {
    $self: &;
    #{$self}__first-block {
      margin-top: 72px;
    }
    #{$self}__last-block {
      margin-bottom: 72px;
    }
    #{$self}__header-image-block {
      margin-top: 56px;
    }
    #{$self}__block {
      margin-top: 72px;
      margin-bottom: 72px;
    }
    #{$self}__block-alt {
      padding-top: 72px;
      padding-bottom: 72px;
    }
    #{$self}__card-block {
      padding: 36px;
    }
  }
}

.bd-highlighted-section {
  border-left: 2px solid map.get($basic, 'brand-basic-background');
  padding-left: 15px;
}
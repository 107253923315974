@use 'sass:map';
@import './variables';

@mixin bd-round-1() {
  border-radius: map.get($bd-round-radius, 'radius-1')
}

@mixin bd-round-2() {
  border-radius: map.get($bd-round-radius, 'radius-2')
}

@mixin bd-round-3() {
  border-radius: map.get($bd-round-radius, 'radius-3')
}

@mixin bd-round-4() {
  border-radius: map.get($bd-round-radius, 'radius-4')
}

@mixin bd-fw-normal() {
  font-weight: map.get($bd-font-weight, 'normal');
}

@mixin bd-fw-medium() {
  font-weight: map.get($bd-font-weight, 'medium');
}

@mixin bd-fw-semi-bold() {
  font-weight: map.get($bd-font-weight, 'semi-bold');
}

@mixin bd-fw-bold() {
  font-weight: map.get($bd-font-weight, 'bold');
}

@mixin bd-sm-and-up {
  @media screen and (min-width: map.get($bd-grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin bd-md-and-up {
  @media screen and (min-width: map.get($bd-grid-breakpoints, 'md')) {
    @content;
  }
}

@mixin bd-lg-and-up {
  @media screen and (min-width: map.get($bd-grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin bd-xl-and-up {
  @media screen and (min-width: map.get($bd-grid-breakpoints, 'xl')) {
    @content;
  }
}

@mixin bd-xxl-and-up {
  @media screen and (min-width: map.get($bd-grid-breakpoints, 'xxl')) {
    @content;
  }
}

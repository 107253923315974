@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-launch-form {
  &_body {
    background: linear-gradient(
      180deg,
      map.get($basic, 'brand-basic-background-gradient-start') 0%,
      map.get($basic, 'brand-basic-background-gradient-end') 34.5%,
      map.get($basic, 'brand-basic-background-gradient-end') 34.71%,
      map.get($color_palette, 'background-primary') 34.92%
    );
  }
}

@include bd-xxl-and-up() {
  .bd-launch-form {
    &_body {
      background: transparent;
    }
  }
}

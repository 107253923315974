@use 'sass:map';
@import '../abstract/mixins';


.bd-home-page-header-block {
  padding-top: 56px;
}

@include bd-sm-and-up() {
  .bd-home-page-header-block {
    padding-top: 96px;
  }
}

@include bd-xxl-and-up() {
  .bd-home-page-header-block {
    padding-top: 144px;
  }
}

@use 'sass:map';
@import '../abstract/variables';

@mixin nav-link() {
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: map.get($color_palette, 'foreground-primary');
}

.bd-nav-panel {
  &__body {
    width: 100%;
    max-width: 900px;
  }
  &__toggler {
    border: none !important;
    padding: 27.5px 10px !important;
    &:focus {
      border: none !important;
    }
  }
  &__item {
    @include nav-link();
    padding: 27px 0;
    box-sizing: border-box;
    justify-content: space-between;
    &:hover {
      color: map.get($basic, 'brand-basic-foreground-alt');
    }
    &.show {
      color: map.get($basic, 'brand-basic-foreground-alt');
      border-bottom: 2px solid map.get($basic, 'brand-basic-background');
    }
  }
  &__ddl-container {
    min-width: 250px !important;
    border: none !important;
  }
  &__subitem {
    @include nav-link();
    padding: 7px 15px;
    &:hover {
      color: map.get($color_palette, 'foreground-secondary');
      background-color: map.get($color_palette, 'background-accent');
      .bd-nav-panel__icon {
        &_flag {
          background-image: url('/assets/icons/flag.png');
        }
        &_message {
          background-image: url('/assets/icons/blog.png');
        }
        &_lifebuoy {
          background-image: url('/assets/icons/lifebuoy.png');
        }
        &_box {
          background-image: url('/assets/icons/box.png');
        }
        &_joystick {
          background-image: url('/assets/icons/gamepad.png');
        }
        &_doc {
          background-image: url('/assets/icons/book.png');
        }
        &_code {
          background-image: url('/assets/icons/code.png');
        }
        &_multitool {
          background-image: url('/assets/icons/colors.png');
        }
        &_hosting {
          background-image: url('/assets/icons/hosting.png');
        }
        &_puzzle {
          background-image: url('/assets/icons/ticket.png');
        }
        &_prize-cup {
          background-image: url('/assets/icons/cup.png');
        }
        &_story {
          background-image: url('/assets/icons/book_opened.png');
        }
      }
    }
  }
  &__icon {
    width: 23px;
    height: 23px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &_flag {
      background-image: url('/assets/icons/flag_bw.png');
    }
    &_message {
      background-image: url('/assets/icons/blog_bw.png');
    }
    &_lifebuoy {
      background-image: url('/assets/icons/lifebuoy_bw.png');
    }
    &_box {
      background-image: url('/assets/icons/box_bw.png');
    }
    &_joystick {
      background-image: url('/assets/icons/gamepad_bw.png');
    }
    &_doc {
      background-image: url('/assets/icons/book_bw.png');
    }
    &_code {
      background-image: url('/assets/icons/code_bw.png');
    }
    &_multitool {
      background-image: url('/assets/icons/colors_bw.png');
    }
    &_hosting {
      background-image: url('/assets/icons/hosting_bw.png');
    }
    &_puzzle {
      background-image: url('/assets/icons/ticket_bw.png');
    }
    &_prize-cup {
      background-image: url('/assets/icons/cup_bw.png');
    }
    &_story {
      background-image: url('/assets/icons/book_opened_bw.png');
    }
  }
}
@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

$card-padding: 1rem;

@mixin bd-card-hover($start, $end) {
  background: linear-gradient(180deg, $start 0%, $end 100%);
  box-shadow: 0px 4px 30px rgba($end, 0.15);
}

.bd-card-wrapper {
  @include bd-round-3();
  padding: 1px;
  &_basic:hover {
    @include bd-card-hover(map.get($basic, 'brand-basic-background-gradient-start'), map.get($basic, 'brand-basic-background-gradient-end'));
  }
  &_danger:hover {
    @include bd-card-hover(map.get($basic, 'brand-danger-background-gradient-start'), map.get($basic, 'brand-danger-background-gradient-end'));
  }
  &_warn:hover {
    @include bd-card-hover(map.get($basic, 'brand-warn-background-gradient-start'), map.get($basic, 'brand-warn-background-gradient-end'));
  }
  &_success:hover {
    @include bd-card-hover(map.get($basic, 'brand-success-background-gradient-start'), map.get($basic, 'brand-success-background-gradient-end'));
  }
  &_info:hover {
    @include bd-card-hover(map.get($basic, 'brand-info-background-gradient-start'), map.get($basic, 'brand-info-background-gradient-end'));
  }
  &_attention:hover {
    @include bd-card-hover(map.get($basic, 'brand-attention-background-gradient-start'), map.get($basic, 'brand-attention-background-gradient-end'));
  }
}

.bd-card {
  @include bd-round-3();
  overflow: hidden;
  padding: $card-padding;
  background-color: map.get($color_palette, 'background-secondary');
  border: 1px solid map.get($color_palette, 'background-accent');

  &__logo {
    width: 4rem;
    height: 4rem;
  }

  &__header-image {
    margin-top: -$card-padding;
    margin-left: -$card-padding;
    margin-right: -$card-padding;
    min-height: 170px;
  }
}

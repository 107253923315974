@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-system-error-image {
  width: 100%;
  height: 331px;
}

.bd-bg-image {
  background-size: cover;
  background-repeat: no-repeat;

  &_center {
    background-position: center;
  }
}

.bd-flag-icon {
  height: 24px;
  width: 36px !important;
}

@include bd-md-and-up() {
  .bd-system-error-image {
    width: auto;
  }
}

@use 'sass:map';
@import '../abstract/variables';

.bd-bottom-separator {
  border-bottom: 1px solid map.get($color_palette, 'background-accent');
}

.bd-border {
  border: 1px solid map.get($color_palette, 'background-accent');
}

.bd-rounded-1 {
  border-radius: map.get($bd-round-radius, 'radius-1') !important;
}

.bd-rounded-2 {
  border-radius: map.get($bd-round-radius, 'radius-2') !important;
}

.bd-rounded-3 {
  border-radius: map.get($bd-round-radius, 'radius-3') !important;
}

.bd-rounded-4 {
  border-radius: map.get($bd-round-radius, 'radius-4') !important;
}

.bd-rounded-5 {
  border-radius: map.get($bd-round-radius, 'radius-5') !important;
}

@use 'sass:map';
@import '../abstract/variables';

.bd-background {
  &-primary {
    background-color: map.get($color_palette, 'background-primary') !important;
  }
  &-accent {
    background-color: map.get($color_palette, 'background-accent') !important;
  }
  &-secondary {
    background-color: map.get($color_palette, 'background-secondary') !important;
  }
  &-basic {
    background-color: map.get($basic, 'brand-basic-background') !important;
  }
  &-warn {
    background-color: map.get($basic, 'brand-warn-background') !important;
  }
  &-basic-gradient {
    background: linear-gradient(180deg, map.get($basic, 'brand-basic-background-gradient-start') 0%, map.get($basic, 'brand-basic-background-gradient-end') 100%);
  }
  &-info-gradient {
    background: linear-gradient(180deg, map.get($basic, 'brand-info-background-gradient-start') 0%, map.get($basic, 'brand-info-background-gradient-end') 100%);
  }
}

.bd-text {
  &-primary {
    color: map.get($color_palette, 'foreground-primary') !important;
  }
  &-primary-alt {
    color: map.get($color_palette, 'foreground-primary-alt') !important;
  }
  &-secondary {
    color: map.get($color_palette, 'foreground-secondary') !important;
  }
  &-accent {
    color: map.get($color_palette, 'foreground-accent') !important;
  }
  &-brand-basic {
    color: map.get($basic, 'brand-basic-foreground') !important;
  }
  &-brand-basic-alt {
    color: map.get($basic, 'brand-basic-foreground-alt') !important;
  }
  &-brand-basic-accent {
    color: map.get($basic, 'brand-basic-background') !important;
  }
  &-brand-warn-accent {
    color: map.get($basic, 'brand-warn-foreground-accent') !important;
  }
}

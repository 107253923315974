@use 'sass:map';
@import '../abstract/variables';

.bd-link {
  text-decoration: none;
  &_underline {
    text-decoration: underline;
  }
  &:hover {
    color: map.get($color_palette, 'foreground-secondary');
  }
  &_primary {
    color: map.get($color_palette, 'foreground-accent');
  }
  &_secondary {
    color: map.get($color_palette, 'foreground-primary-alt');
  }
  &_secondary-alt {
    color: map.get($color_palette, 'foreground-primary');
  }
  &_accent {
    color: map.get($basic, 'brand-basic-foreground-alt');
  }
  &_info {
    color:  map.get($basic, 'brand-info-foreground-alt');
  }
  &_basic {
    color:  map.get($basic, 'brand-basic-foreground-alt');
  }
}

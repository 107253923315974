@use 'sass:map';
@import '../abstract/mixins';

@include bd-md-and-up() {
  .bd-steps {
    &:nth-child(even) {
      padding-left: 5rem;
    }
    &:nth-child(odd) {
      padding-right: 5rem;
    }
  }
}

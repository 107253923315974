@import '../abstract/mixins';

.bd-w-100 {
  width: 100%;
}

@include bd-sm-and-up() {
  .bd-w-sm-auto {
    width: auto;
  }
}
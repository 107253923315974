@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-form-control {
  &__input {
    @include bd-round-3();
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid;
    background-color: map.get($color_palette, 'background-secondary');
    color: map.get($color_palette, 'foreground-primary');
    border-color: map.get($color_palette, 'foreground-primary-alt');
  }
}

.bd-checkbox {
  accent-color: map.get($basic, 'brand-basic-background');
}

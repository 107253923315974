@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-breadcrumbs {
  @include bd-fw-semi-bold();
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__item {
    padding-left: .2rem;

    &:not(:first-child) {
      &::before {
        float: left;
        padding-right: .2rem;
        color: map.get($color_palette, 'foreground-primary-alt');
        content: "/";
      }
    }
  }
}

@use 'sass:map';
@import '../abstract/variables';

.markdown-it {
  img {
    width: 100%;
  }
  h1, h2, h3, h4, h5, h6 {
    color: map.get($color_palette, 'foreground-secondary');
    padding-top: 32px;
  }
  a {
    color: map.get($basic, 'brand-basic-foreground-alt');
    text-decoration: none;
    &:hover {
      color: map.get($color_palette, 'foreground-secondary');
    }
  }
} 

@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-ff {
  &-mono {
    font-family: 'Roboto Mono', monospace;
  }
}

.bd-lh {
  &-xs {
    line-height: map.get($bd-line-height, 'xs') !important;
  }
  &-sm {
    line-height: map.get($bd-line-height, 'sm') !important;
  }
  &-md {
    line-height: map.get($bd-line-height, 'md') !important;
  }
  &-lg {
    line-height: map.get($bd-line-height, 'lg') !important;
  }
  &-xl {
    line-height: map.get($bd-line-height, 'xl') !important;
  }
  &-xxl {
    line-height: map.get($bd-line-height, 'xxl') !important;
  }
  &-xxxl {
    line-height: map.get($bd-line-height, 'xxxl') !important;
  }
}

.bd-fs {
  &-xs {
    font-size: map.get($bd-font-size, 'xs') !important;
  }
  &-sm {
    font-size: map.get($bd-font-size, 'sm') !important;
  }
  &-lg {
    font-size: map.get($bd-font-size, 'lg') !important;
  }
  &-xl {
    font-size: map.get($bd-font-size, 'xl') !important;
  }
  &-xxl {
    font-size: map.get($bd-font-size, 'xxl') !important;
  }
  &-xxxl {
    font-size: map.get($bd-font-size, 'xxxl') !important;
  }
}

@include bd-md-and-up() {
  .bd-fs {
    &-xl {
      font-size: map.get($bd-md-font-size, 'xl') !important;
    }
    &-sm {
      font-size: map.get($bd-md-font-size, 'sm') !important;
    }
    &-lg {
      font-size: map.get($bd-md-font-size, 'lg') !important;
    }
    &-xl {
      font-size: map.get($bd-md-font-size, 'xl') !important;
    }
    &-xxl {
      font-size: map.get($bd-md-font-size, 'xxl') !important;
    }
    &-xxxl {
      font-size: map.get($bd-md-font-size, 'xxxl') !important;
    }
  }
}

@use 'sass:map';
@import '../abstract/variables';
@import '../abstract/mixins';

.bd-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  background-color: map.get($color_palette, 'background-secondary');

  &__item {
    text-decoration: none;
    @include bd-round-3();
    @include bd-fw-semi-bold();
    color: map.get($color_palette, 'foreground-primary');
    background-color: transparent;
    border: 1px solid map.get($color_palette, 'foreground-primary-alt');
    width: 2.75rem;
    height: 2.75rem;
    &:hover {
      color: map.get($color_palette, 'foreground-accent');
    }
    &_active {
      background-color: map.get($color_palette, 'background-accent');
    }
  }
}

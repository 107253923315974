@use 'sass:map';
@import '../abstract/variables';

$subscribe-button-size: 180px;

.bd-btn {
  border: none;
  border-radius: 12px;
  padding: 12px 18px;
  text-decoration: none;

  &_basic {
    border: 1px solid map.get($basic, 'brand-basic-background'); // include border height into button height
    color: map.get($basic, 'brand-basic-foreground');
    background-color: map.get($basic, 'brand-basic-background');
    &:hover {
      color: map.get($color_palette, 'foreground-accent') !important;
    }
  }

  &_highlight-basic {
    border: 1px solid map.get($basic, 'brand-basic-foreground-alt');
    color: map.get($color_palette, 'foreground-accent');
    background-color: map.get($basic, 'brand-basic-background');
    &:hover {
      color: map.get($color_palette, 'foreground-accent');
    }
  }

  &_outline-basic {
    border: 1px solid map.get($color_palette, 'background-accent');
    color: map.get($color_palette, 'foreground-primary');
    background-color: transparent;
    .btn-onhover-highlight:hover & {
      background-color: map.get($basic, 'brand-basic-background');
      color: map.get($color_palette, 'foreground-accent');
    }
    &:hover {
      background-color: map.get($basic, 'brand-basic-background');
      color: map.get($color_palette, 'foreground-accent');
    }
  }

  &_outline-info {
    border: 1px solid map.get($color_palette, 'background-accent');
    color: map.get($color_palette, 'foreground-primary');
    background-color: transparent;
    .btn-onhover-highlight:hover & {
      background-color: map.get($basic, 'brand-info-foreground');
      color: map.get($color_palette, 'foreground-accent');
    }
    &:hover {
      background-color: map.get($basic, 'brand-info-foreground');
      color: map.get($color_palette, 'foreground-accent');
    }
  }

  &_sm {
    padding: 6px 9px;
  }
}

.bd-subscribe-btn {
  height: $subscribe-button-size;
  width: $subscribe-button-size;
}
